import { TicketshopPage } from "components/TicketshopPage";
import React from "react";

export default function AllEventsPage() {
  return (
    <TicketshopPage
      scriptString={`<script src="https://web-cdn.fixr.co/scripts/fixr-shop-widget.v1.min.js?salesChannel=whitelabel-website" data-fixr-shop-id="5b62d78f-2a77-4523-9a02-937a290fb62b" data-fixr-theme="light"></script>`}
      imageSrc="/event-logo-all.png"
      imageStyle={{ maxWidth: 562 }}
      title="ALL EVENTS"
    />
  );
}
